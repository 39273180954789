import {useNavigate} from "react-router-dom";
import {DynamicContainer, NotesLibrary} from "@plumeuk/shapeshift-common/v2";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles()((theme) => ({
	pageBase: {},
	notesSectionTitle: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(4)
	}
}));

export const NotesPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<DynamicContainer size="wide">
				<Typography variant="h3" className={classes.notesSectionTitle}>Notes library</Typography>
				<NotesLibrary onGoToModuleAction={(courseId, moduleId, moduleType, timestamp) => {navigate(`/course/${courseId}/${moduleType}/${moduleId}`, {state: {timestamp}})}}/>
			</DynamicContainer>
		</PageBase>
	);
}